@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.loader {
    margin-top: 16px;
    border-top: 10px solid #58b647;
    border-right: 10px solid rgba(136, 136, 136, 0.2);
    border-bottom: 10px solid rgba(136, 136, 136, 0.2);
    border-left: 10px solid rgba(136, 136, 136, 0.2);
    animation: spinner 0.9s linear infinite;
    border-radius: 50%;
    width: 64px;
    height: 64px;
}

.loading-text {
    margin-top: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    color: #58b647;
    background-color: 10px solid rgba(3, 3, 3, 0.2);
}