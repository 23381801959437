.syrup-button {
    color: white;
    border-radius: 5px;
    text-shadow: 0 0 0 black;
    transition: text-shadow 0.2s;
    box-shadow: 0 1px 2px black;
}

.syrup-button:focus {
    outline: none;
}

.syrup-button:hover {
    text-shadow: 0 0 2px darkgray;
}

.syrup-button:active {
    box-shadow: inset 0 0 0.2em rgb(255, 255, 255);
}
