.MuiRadio-colorSecondary.Mui-checked {
    color: rgb(253, 0, 0) !important;
}

/* Items Detail */

.item-detail-root {
    width: 100%;
    display: flow-root;
    padding: 1px;
    margin-bottom: 5px
}

.item-detail-content {
    width: 100%;
}

.item-detail-header {
    margin: 0 5px;
    position: relative;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.item-detail-textheader {
    align-items: cente;
    text-align: left;
    font-size: 13px;
    font-weight: 600!important;
    z-index: 100;
    height: 100%;
    width: 100%;
    position: absolute
}

.item-detail-radiogroup {
    width: 100%;
    font-Size: 12px
}

.item-detail-contentitem {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: auto 70px;
}

/* .item-detail-formradio {
    width: 100%;
    font-size: 12px;
    display: flex;
} */

.item-detail-txttotal {
    font-size: 20px !important
}

.item-detail-txtitem {
    font-size: 14px !important
}

.item-detail-txtitem span {
    font-size: 12px !important;
}

.item-detail-txtitem span span {
    font-size: 16px !important;
}

.item-detail-contentbtns {
    display: flex;
    align-items: center
}

.item-detail-containercant {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
}

.item-detail-txtcant {
    min-width: 20px;
    max-width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.item-detail-btncant {
    max-width: 25px!important;
    max-height: 25px!important;
    min-width: 20px!important;
    min-height: 20px!important;
    display: flex!important;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    background: transparent;
    border: none!important
}

.item-detail-btncant :hover {
    border: none!important
}

.item-detail-btncant :active {
    border: none!important
}

.item-detail-txtNombreItem {
    font-size: 12px
}

.item-detail-radioicon {
    position: relative;
    display: block;
    margin: 0;
    width: 12px;
    height: 12px;
}

.item-detail-textarea {
    width: 100%;
    min-height: 80px;
    margin-top: 5px;
}

.item-detail-txtprecioItem {
    position: "relative"
}

.item-detail-btnpedir {
    height: 40px;
    width: 50%;
    min-width: 70px!important;
    min-height: 40px!important;
    display: flex!important;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    border: none!important;
}

/* fin items detail */