.MuiRadio-colorSecondary.Mui-checked {
    color: rgb(253, 0, 0) !important;
}

/* Items Cart */

.item-cart-containerimage {
    align-items: center;
    height: 100px;
    display: flex;
    margin: 7px;
}

.item-cart-image {
    border-radius: 50%;
    min-width: 100px;
    min-height: 100px;
    max-width: 100px !important;
    max-height: 100px !important;
    margin: 0 auto;
}

.item-cart-root {
    position: relative;
    margin-top: 55px !important;
    padding: 5px;
    background-color: transparent !important;
    width: 100%;
    display: flow-root;
    margin-bottom: 5px
}

.item-cart-contentitem {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: auto 180px 110px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.item-cart-txtitemextra {
    font-size: 0.9em !important;
}

.item-cart-containertxt {
    align-items: center;
    height: 100%;
    display: inline-block;
    padding: 5 px;
}

.item-cart-txttotal {
    font-size: 24px !important;
    font-weight: 600 !important;
    text-align: center;
}

.item-cart-txtitem {
    font-size: 1em !important;
    font-weight: 600 !important;
}

.item-cart-txtitem span {
    font-size: 12px !important;
    font-weight: 800 !important;
}

.item-cart-txtitem span span {
    font-size: 16px !important;
}

.item-cart-containercant {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
}

.item-cart-btncant {
    max-width: 25px !important;
    max-height: 25px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    background: transparent;
    border: none !important
}

.item-cart-txtcant {
    min-width: 20px;
    max-width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

@media only screen and (max-width: 768px) {
    .item-cart-root {
        margin-top: 160px !important;
    }

    .item-cart-containerimage {
        display: none;
    }

    .item-cart-txttotal {
        text-align: right;
    }

    .item-cart-contentitem {
        display: grid;
        grid-template-columns: auto;
    }

    .item-cart-containercant {
        justify-content: center;
        margin-bottom: 5px;
    }
}

/*fin*/

/*  

.item-detail-root {
    width: 100%;
    display: flow-root;
    padding: 1px;
    margin-bottom: 5px
}

.item-detail-content {
    width: 100%;
}

.item-detail-header {
    margin: 0 5px;
    position: relative;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.item-detail-textheader {
    align-items: cente;
    text-align: left;
    font-size: 13px;
    font-weight: 600!important;
    z-index: 100;
    height: 100%;
    width: 100%;
    position: absolute
}

.item-detail-radiogroup {
    width: 100%;
    font-Size: 12px
}

.item-detail-contentitem {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: auto 70px;
}

 

.item-detail-txttotal {
    font-size: 20px !important
}

.item-detail-txtitem {
    font-size: 14px !important
}

.item-detail-txtitem span {
    font-size: 12px !important;
    font-weight: 600 !important;
}

.item-detail-txtitem span span {
    font-size: 16px !important;
}

.item-detail-contentbtns {
    display: flex;
    align-items: center
}

.item-detail-containercant {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
}

.item-detail-txtcant {
    min-width: 20px;
    max-width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.item-detail-btncant {
    max-width: 25px!important;
    max-height: 25px!important;
    min-width: 20px!important;
    min-height: 20px!important;
    display: flex!important;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    background: transparent;
    border: none!important
}

.item-detail-btncant :hover {
    border: none!important
}

.item-detail-btncant :active {
    border: none!important
}

.item-detail-txtNombreItem {
    font-size: 12px
}

.item-detail-radioicon {
    position: relative;
    display: block;
    margin: 0;
    width: 12px;
    height: 12px;
}

.item-detail-textarea {
    width: 100%;
    min-height: 80px;
    margin-top: 5px;
}

.item-detail-txtprecioItem {
    position: "relative"
}

  */