.syrup-input-group {
    box-shadow: 0 0 2px darkgray;
    border-radius: 1px;
    display: flex;
}

.syrup-input-group * {
    border-radius: 0;
    border-left: 1px solid gray;
    margin: 2px;
}

.syrup-input-group *:first-child {
    border-radius: 0;
    border-left: none;
}

.syrup-input-group *:last-child {
    border-radius: 0;
}

.syrup-select-input {
    height: 30px;
    width: 100%;
    flex-grow: 1;
    border: none;
    box-shadow: inset 0 2px 4px gray;
}

.error {
    color: red;
}

.syrup-select-input:focus {
    outline: none;
}

.syrup-text-input {
    width: 100%;
    flex-grow: 1;
    border: none;
    box-shadow: inset 0 2px 4px gray;
}

.syrup-text-input:focus {
    outline: none;
}