.btnMenu:hover {
    background-color: #7d1414;
}
.topbar-desktop {
    margin-top: 5px;
    margin-bottom: 5px;
    display: grid !important;
    grid-template-columns: 120px auto 200px;
    gap: 3px;
}

.topbar-movil {
    display: none !important;
}

.desktop {
    display: flex !important;
}

.movil {
    display: none !important;
}

.carousel {
    background-color: white;
}

.icons {
    box-shadow: 0 0 0 0.25rem rgba(0, 4, 10, 0.25);
}

@media (max-width: 768px) {
    .topbar-desktop {
        display: none !important;
    }
    .topbar-movil {
        display: grid !important;
        grid-template-columns: 120px auto;
    }
    .carousel {
        padding-bottom: 5px;
    }
    .desktop {
        display: none !important;
    }
    .movil {
        display: flex !important;
    }
}
