html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  min-width: 100vw;
  min-height: 100vh;
}

.container-fluid {
  padding: 0 !important;
  margin: 0 !important;
}

body {
  background: #ffffff;
}